import React, { Component } from 'react';
import 'normalize.css/normalize.css'
import './css/html5_normalize.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/all.css'
// import {ThemeContext, themes} from './themes/ThemeContext'
import '@fortawesome/fontawesome-free/css/all.min.css'

class App extends Component {
  render() {
    return (
      <div className="app container-fluid d-flex flex-column vh-100">

        <header className="position-absolute">
          <h1 className="sr-only">BIC App LLC</h1>
          <a href="#" className="header_logo">bicapp.io</a>
        </header>

        {/*// <!-- Trick here is to:*/}
        {/*//   (a) use `row` to nullify the margin on the outside created*/}
        {/*//       by body's `container-fluid`*/}
        {/*//   (b) use inner nested `row` to house the columns and to apply*/}
        {/*//       `no-gutter` on the row as per the grid layout*/}
        {/*// -->*/}

        {/* <!-- outer row here to nullify the `container-fluid` --> */}
        <main className="flex-fill d-flex row">
          <h1 className="sr-only">Tenets</h1>
          <article className="flex-fill row no-gutters"> {/* <!-- inner row to house the col(s) --> */}
            <section className="col left_panel d-flex justify-content-center align-items-center flex-column">
              <h1 className="keyword align-self-start">Build</h1>
              <div className="image_container d-flex flex-fill justify-content-center align-items-center">
                <i className="fas fa-rocket"></i>
              </div>
              <div className="description align-self-start">Building pervasive and collaborative technologies.</div>
            </section>

            <div className="col d-flex flex-column">
              <section className="right_panel_top flex-fill d-flex flex-column">
                <h1 className="keyword align-self-start">Ideate</h1>
                <div className="image_container d-flex flex-fill justify-content-center align-items-center">
                  <i className="fas fa-gem"></i>
                </div>
                <div className="description">Ideations and imaginations coming to life.</div>
              </section>

              <section className="right_panel_bottom flex-fill  d-flex flex-column">
                <h1 className="keyword">Code</h1>
                <div className="image_container d-flex flex-fill justify-content-center align-items-center">
                  <i className="fas fa-code"></i>
                </div>
                <div className="description">Code that works.</div>
              </section>
            </div>
          </article>
        </main>

        <footer className="footer row">
          <h1 className="sr-only">Copyright Notice</h1>
          <div className="col d-flex justify-content-center align-items-center py-2">
            <div>BICApp LLC &copy; 2018 - present</div>
          </div>
        </footer>

      </div>
    );
  }
}

export default App;
